import { ToastOptions, toast } from 'react-toastify';
import { ErrorCircle, IconMessageWrap } from './ToastsStyled';
import { Icon, IconAlert, IconAlertSuccess } from 'styles/svg';
import { P2, P3 } from 'styles/FontStyles';
import { colors } from 'styles/GlobalStyles';

const toastConfig: ToastOptions = {
  icon: false,
  hideProgressBar: true,
  pauseOnHover: true,
  autoClose: 5000,
  position: 'top-center',
  draggable: true,
  closeOnClick: true,
  theme: 'dark',
};

export const SuccessToast = (successMessage?: string) => {
  toast.success(
    <SuccessToastComponent successMessage={successMessage ?? 'Success'} />,
    toastConfig
  );
};

export const ErrorToast = (errorMessage?: string) => {
  toast.error(
    <ErrorToastComponent errorMessage={errorMessage ?? 'Error'} />,
    toastConfig
  );
};

export const SuccessToastComponent = ({
  successMessage,
}: {
  successMessage: string;
}) => (
  <>
    <IconMessageWrap>
      <ErrorCircle success>
        <Icon color={colors.neutral50}>
          <IconAlertSuccess />
        </Icon>
      </ErrorCircle>
      <P2 color={colors.neutral0}>{successMessage}</P2>
    </IconMessageWrap>
  </>
);

export const ErrorToastComponent = ({
  errorMessage,
}: {
  errorMessage: string;
}) => (
  <>
    <IconMessageWrap>
      <ErrorCircle>
        <Icon color={colors.neutral50}>
          <IconAlert />
        </Icon>
      </ErrorCircle>

      <P3 color={colors.neutral0}>{errorMessage}</P3>
    </IconMessageWrap>
  </>
);
