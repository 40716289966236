import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

export const IconWrap = styled.div`
  flex: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;

  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #5a5a5a;
`;

export const IconMessageWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledToastContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    z-index: 2147483647;
    max-width: 500px;
    width: calc(100% - 40px);
    top: 20px;
    left: 50%;
    transform: translateX(-50%);

    .Toastify__toast {
      border-radius: 8px;
      margin-bottom: 16px;
      padding: 10px 15px;
    }
    .Toastify__toast-body {
      padding: 0;
      margin: 0;
    }
    .Toastify__progress-bar {
    }
    .Toastify__close-button {
      align-self: center;
    }

    .Toastify__close-button > svg {
      height: 22px;
      width: 22px;
    }
  }
`;

export const ErrorCircle = styled.div<{ success?: boolean }>`
  width: 34px;
  height: 34px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 15px;
  flex: none;
`;
