import { useState } from 'react';
import { Div, DownloadIcon, H1, Img, P1, P2, colors } from 'styles';
import chapter1 from '../../../assets/images/chapter1img.png';
import chapter2 from '../../../assets/images/chapter2img.png';
import chapter3 from '../../../assets/images/chapter3img.png';
import chapter4 from '../../../assets/images/chapter4img.png';
import chapter5 from '../../../assets/images/chapter5img.png';
import chapter6 from '../../../assets/images/chapter6img.png';
import {
  DownloadButtonWrapper,
  GradientText,
  Main,
  Section4Wrapper,
} from '../Home.styled';
import { Litepaper } from '../components/Litepaper';
import { openPdfInNewWindow } from 'helpers/helpers';

export const Section5 = () => {
  const [expanded, setExpanded] = useState<number | null>(null);

  return (
    <Section4Wrapper id='section4'>
      <GradientText>
        <H1 mb='50'>Litepaper</H1>
      </GradientText>
      <Main>
        {litePapers.map(({ text, title, img }, i) => (
          <Litepaper
            title={title}
            index={i + 1}
            expanded={expanded}
            setExpanded={setExpanded}
          >
            <Div $flex $alignCenter $gap='50px' $gapSM='20px' $columnSM>
              <Div $width='75%' $widthSM='100%'>
                <P2 color={colors.white}>{text}</P2>
              </Div>
              <Div $width='25%' $widthSM='100%' $flex $center>
                <Img $width='60%' $widthSM='40%' src={img} />
              </Div>
            </Div>
          </Litepaper>
        ))}
        <Div $flex $center $mt='50px'>
          <DownloadButtonWrapper
            onClick={() => openPdfInNewWindow('/Akasha Litepaper.pdf')}
          >
            <GradientText>
              <P1>Download PDF</P1>
            </GradientText>
            <DownloadIcon />
          </DownloadButtonWrapper>
        </Div>
      </Main>
    </Section4Wrapper>
  );
};

const litePapers = [
  {
    title: 'Genesis & The Energy of Creation',
    img: chapter1,
    text: (
      <>
        The universe is intricately connected, with each part serving a purpose.
        Technology, at its highest form, reflects this complexity, mirroring
        creation itself. Just as God designed the cosmos, humanity is gifted
        with the power to create, using tools like technology to expand
        potential and understanding.
        <br />
        At the heart of existence is the energy driving creation and
        progress—known as the Holy Spirit, Qi, Ki, or Akasha. This life force
        sustains all living beings and binds the universe together.The Akasha
        Project seeks to replicate this interconnectedness digitally, seamlessly
        integrating life and commerce through technology.
      </>
    ),
  },
  {
    title: 'A Unified World Through Interoperability',
    img: chapter2,
    text: (
      <>
        The universe expands continuously, and so should humanity’s
        contributions to it. The Akasha Project aims to build a digital
        infrastructure that unites all systems, allowing seamless interaction
        across domains. Through our AI-powered interoperability layer, users can
        transfer data and assets with a single command, erasing the complexities
        that currently inhibit blockchain adoption.
        <br />
        <br />
        This is not just a vision—it’s the reality we’re building. Akasha makes
        technology accessible to everyone, removing the barriers that have long
        kept blockchain systems inaccessible to the average person.
      </>
    ),
  },
  {
    title: 'Akasha’s Roadmap: Path to Universal Connectivity',
    img: chapter3,
    text: (
      <>
        The Akasha Project aims to go beyond technology by eliminating
        traditional financial systems. Our vision is to integrate finance into
        everyday life through blockchain technology. However, current blockchain
        ecosystems are not equipped to handle the complexity of the global
        economy. Usability, security, and customer service remain weak points in
        the industry.
        <br />
        <br />
        We are addressing these challenges by merging the best of traditional
        finance with decentralized blockchain systems. Our focus on user
        education and personalized support ensures that new users are brought
        into the system with confidence and ease.
      </>
    ),
  },
  {
    title: 'The Technology of Akasha',
    img: chapter4,
    text: (
      <>
        Technology should work so effortlessly that it feels invisible. Much
        like the Holy Spirit, which operates without direct recognition, Akasha
        technology functions as an interoperability layer that seamlessly
        bridges Web1, Web2, Web3, and traditional systems. It connects the
        digital and traditional economies in a way that feels natural and
        intuitive.
        <br />
        <br />
        Over six years of development, with a team of 60 senior developers, the
        Akasha system has evolved beyond a simple blockchain solution. It is an
        intelligent network designed to make connections as natural as the
        breath of life itself.
      </>
    ),
  },
  {
    title: 'Akasha’s Vision: A New Financial Ecosystem',
    img: chapter5,
    text: (
      <>
        The universe expands continuously, and so should humanity’s
        contributions to it. The Akasha Project aims to build a digital
        infrastructure that unites all systems, allowing seamless interaction
        across domains. Through our AI-powered interoperability layer, users can
        transfer data and assets with a single command, erasing the complexities
        that currently inhibit blockchain adoption.
        <br />
        <br />
        This is not just a vision—it’s the reality we’re building. Akasha makes
        technology accessible to everyone, removing the barriers that have long
        kept blockchain systems inaccessible to the average person.
      </>
    ),
  },
  {
    title: 'Akasha’s Role in Creating Heaven on Earth',
    img: chapter6,
    text: (
      <>
        Akasha carries the energy of divine purpose, guiding humanity toward
        building a better world. Our mission is aligned with the vision
        described in Matthew 6:10: “Thy Kingdom come, Thy will be done, on Earth
        as it is in Heaven.” The wisdom that Akasha brings will serve as the
        foundation for a new era of human achievement, one where technology and
        spirituality converge.
      </>
    ),
  },
];
