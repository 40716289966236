import { Button } from 'components/Button/Button';
import { NavLink } from 'react-router';
import styled from 'styled-components';

export const NavStyled = styled.nav<{ $sticky: boolean }>`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 30px 80px;
  transition: all 0.2s ease;
  z-index: 100;
  width: 100%;

  img {
    width: 190px;
  }

  ${({ $sticky, theme }) =>
    $sticky &&
    `
    padding: 10px 50px;
    background: ${theme.black}cc;
    `}

  @media (max-width: 1000px) {
    justify-content: space-between;

    padding: 20px 30px;
    img {
      width: 130px;
    }

    ${({ $sticky }) =>
      $sticky &&
      `
    padding: 10px 20px;
  
    `}
  }
`;

export const NavLinkStyled = styled(NavLink)`
  font-size: 18px;
  color: ${({ theme }) => theme.white};
  text-decoration: none;
  :hover {
    color: ${({ theme }) => theme.primary};
  }
`;

export const SignUpButton = styled(Button)`
  height: 40px;
  font-weight: 400;
  width: 110px;
  background: linear-gradient(90deg, #01f1c1 0%, #185779 100%);
`;

export const SignInButton = styled(SignUpButton)`
  font-weight: 400;
  background: none;
  border: 2px solid ${({ theme }) => theme.primary};
`;

export const LogOutButton = styled.div`
  cursor: pointer;
  font-size: 18px;
  color: #fff;
`;
