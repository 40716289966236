import { DefaultTheme, createGlobalStyle } from 'styled-components';

declare module 'styled-components' {
  export interface DefaultTheme {
    background: '#fafafa';
    white: '#ffffff';
    primary: '#F02B00';
    gray: '#E6E6E6';
    black: '#000000';
    blue: '#00E3B2';

    sizes: {
      s1: 4;
      s2: 8;
      s3: 12;
      s4: 16;
      s5: 20;
      s6: 24;
      s7: 28;
      s8: 32;
      s9: 36;
      s10: 40;
    };
    shadows: {
      shadowXS: '0px 4px 8px rgba(0, 0, 0, 0.05)';
      shadowSM: '0px 6px 12px rgba(0, 0, 0, 0.11)';
      shadowMD: ' 0px 9px 18px rgba(0, 0, 0, 0.15)';
      shadowLG: '0px 13px 37px rgba(0, 0, 0, 0.21)';
      shadowXL: '0px 20px 56px rgba(0, 0, 0, 0.29)';
      shadow: '0px 1px 12px #7B61FF';
    };
  }
}

export const sizes = {
  s1: 4,
  s2: 8,
  s3: 12,
  s4: 16,
  s5: 20,
  s6: 24,
  s7: 28,
  s8: 32,
  s9: 36,
  s10: 40,
};

export const colors = {
  background: '#fafafa',
  white: '#ffffff',
  primary: '#75EAE7',
  gray: '#E6E6E6',
  black: '#000000',
  blue: '#00E3B2',

  neutral0: '#ffffff',
  neutral50: '#FFFFFF',
  neutral100: '#F3F3F3',
  neutral400: '#A9A9A9',
  neutral500: '#8E8E8E',
  neutral700: '#5A5A5A',
  neutral900: '#1F1F1F',

  success400: '#00BA00',
  success500: '#18B915',
  error: '#FF0000',
};

export const shadows = {
  shadowXS: '0px 4px 8px rgba(0, 0, 0, 0.05)',
  shadowSM: '0px 6px 12px rgba(0, 0, 0, 0.11)',
  shadowMD: ' 0px 9px 18px rgba(0, 0, 0, 0.15)',
  shadowLG: '0px 13px 37px rgba(0, 0, 0, 0.21)',
  shadowXL: '0px 20px 56px rgba(0, 0, 0, 0.29)',
  shadow: '0px 1px 12px #7B61FF',
};

export const lightTheme = {
  ...colors,
  sizes,
  shadows,
};

export const darkTheme = {};

export const GlobalStyles = createGlobalStyle`
	body {
		background-color: ${({ theme }) => theme.background};
	}
`;

export const theme = localStorage.getItem('givvoBackOfficeTheme');

export const setTheme = () => {
  if (theme === 'light') {
    localStorage.setItem('givvoBackOfficeTheme', 'dark');
    window.location.reload();
  } else if (theme === 'dark') {
    localStorage.setItem('givvoBackOfficeTheme', 'light');
    window.location.reload();
  } else if (!theme) {
    localStorage.setItem('givvoBackOfficeTheme', 'dark');
    window.location.reload();
  }
};

export const chooseTheme = () => {
  if (theme === 'light') {
    return lightTheme as DefaultTheme;
  } else if (theme === 'dark') {
    return darkTheme as DefaultTheme;
  } else {
    return lightTheme as DefaultTheme;
  }
};

export const color = chooseTheme();
