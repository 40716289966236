import { AnimatePresence } from 'framer-motion';
import { Dispatch, ElementType, FC, SetStateAction } from 'react';
import { DropdownStyled } from './dropdownMenu.styled';
import { Div } from '../../styles/Styles';
import { handleSmoothScroll, navLinks } from '../../helpers/helpers';
import { NavLinkStyled } from '../Nav/Nav.styled';
import { colors } from 'styles';

const variants = {
  initial: {
    opacity: 0,
    transform: 'scale(1, 0)',

    transition: {
      duration: 0.2,
      type: 'ease',
    },
  },
  animate: {
    opacity: 1,
    transform: 'scale(1, 1)',
  },

  exit: {
    opacity: 0,
    transform: 'scale(1, 0)',
    transition: {
      duration: 0.2,
      type: 'ease',
    },
  },
};

const AnimatePresenceFixedType = AnimatePresence as ElementType;

interface DropdownMenuType {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  location: { pathname: string };
  navigate: (path: string) => void;
  activeSection: string;
}

export const DropdownMenu: FC<DropdownMenuType> = ({
  isOpen,
  setIsOpen,
  location,
  navigate,
  activeSection,
}) => {
  return (
    <AnimatePresenceFixedType>
      {isOpen && (
        <DropdownStyled
          key='DropdownMenu'
          variants={variants}
          initial='initial'
          animate='animate'
          exit='exit'
        >
          <Div $flex $center $column $gap='30px'>
            {navLinks.map(({ path, name }, i) => (
              <NavLinkStyled
                key={i}
                to={path}
                onClick={(e) => handleSmoothScroll(e, path, location, navigate)}
                style={{ color: activeSection === path ? colors.primary : '' }}
              >
                {name}
              </NavLinkStyled>
            ))}
          </Div>
        </DropdownStyled>
      )}
    </AnimatePresenceFixedType>
  );
};
