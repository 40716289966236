import { styled } from 'styled-components';
import { DisableSelection } from 'styles/Styles';

export const Button = styled.button<{ $color?: string; $white?: boolean }>`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  border-radius: 200px;
  background-color: ${({ theme, $color }) => ($color ? $color : theme.primary)};
  color: ${({ theme }) => theme.white};
  border: none;
  cursor: pointer;

  ${(props) => props.disabled && `opacity: 0.5;`}

  ${(props) => props.disabled && `cursor: not-allowed`};

  ${({ $white, theme }) =>
    $white &&
    `
      background-color: ${theme.white};
      color: ${theme.primary};
  
  `}

  transition: transform 0.1s ease, background-color 0.3s ease, border 0.3s ease,
    color 0.3s ease;

  :active {
    transform: translateY(3px);
  }
  > * {
    ${DisableSelection}
  }
`;
