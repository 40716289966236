import { useEffect, useRef, useState } from 'react';
import { NavLinkStyled, NavStyled } from './Nav.styled';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/akashaLogo.png';
import { handleSmoothScroll, pathname } from '../../helpers/helpers';
import { Div, Img } from '../../styles/Styles';
import { MenuIcon } from '../../styles/svg';
import { DropdownMenu } from '../DropdownMenu/dropdownMenu';

import { ModalComponent } from 'components/Modal/Modal';
import { colors } from 'styles';

export const navLinks = [
  { path: '#section1', name: 'Home' },
  { path: '#section2', name: 'About' },
  { path: '#section3', name: 'Partners' },
  { path: '#section4', name: 'Litepaper' },
  { path: '/resources', name: 'Resources' },
  // { path: '#section4', name: 'Safety' },
];

export const Nav = ({ resources }: { resources?: boolean }) => {
  const [sticky, setSticky] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [activeSection, setActiveSection] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();

  const dropdownRef = useRef(null);

  const handleMenu = () => setDropdown((prevState) => !prevState);

  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + 100;
      let foundActiveSection = false;

      // ✅ Handle sections with `#sectionX`
      for (const link of navLinks) {
        if (link.path.startsWith('#')) {
          const sectionId = link.path.replace('#', '');
          const section = document.getElementById(sectionId);

          if (section) {
            const { top, height } = section.getBoundingClientRect();
            const sectionTop = top + window.scrollY;
            const sectionBottom = sectionTop + height;

            if (
              scrollPosition >= sectionTop &&
              scrollPosition < sectionBottom - 50
            ) {
              setActiveSection(link.path);
              window.history.replaceState(null, '', link.path);
              foundActiveSection = true;
              break;
            }
          }
        }
      }

      // ✅ If no section is found active, set `activeSection` to the full page route
      if (!foundActiveSection) {
        setActiveSection(location.pathname);

        // // ✅ Scroll to top if the page has changed
        // if (location.pathname !== window.location.pathname) {
        //   window.scrollTo({ top: 0, behavior: 'smooth' });
        // }
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initialize on load

    return () => window.removeEventListener('scroll', handleScroll);
  }, [location]); // ✅ Trigger when `location` changes

  useEffect(() => {
    if (window.location.hash) {
      const targetId = window.location.hash.replace('#', '');
      const targetElement = document.getElementById(targetId);

      if (targetElement) {
        setTimeout(() => {
          targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 100);
      }
    }
  }, [location]);

  useEffect(() => {
    if (dropdown) {
      handleMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const isSticky = () => {
    const scrollTop = window.scrollY;
    scrollTop >= 50 ? setSticky(true) : setSticky(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  });

  return (
    <>
      <NavStyled $sticky={sticky}>
        <Div $flex $alignCenter $gap='80px'>
          <Link to='/'>
            <Img
              $mt='20'
              $mr='50'
              $width={'180px'}
              $widthSM={'50px'}
              src={logo}
              alt='Akasha'
            />
          </Link>
        </Div>

        <Div $desktop $flex $alignCenter $gap='30px'>
          {navLinks.map(({ path, name }, i) => (
            <NavLinkStyled
              key={i}
              to={path}
              onClick={(e) => handleSmoothScroll(e, path, location, navigate)}
              style={{ color: activeSection === path ? colors.primary : '' }}
            >
              {name}
            </NavLinkStyled>
          ))}
        </Div>
        <Div $flex $alignCenter $gap='24px'>
          {/* <SignInButton onClick={() => navigate(SIGNIN)}>
                    Sign In
                  </SignInButton> */}
          {/* <Div>
                    <a
                      href='https://easymoney.info/'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <SignUpButton>Use Akasha</SignUpButton>
                    </a>
                  </Div> */}

          <Div $mobile ref={dropdownRef}>
            <Div $pointer onClick={handleMenu}>
              <MenuIcon />
            </Div>
            <DropdownMenu
              isOpen={dropdown}
              setIsOpen={setDropdown}
              location={location}
              navigate={navigate}
              activeSection={activeSection}
            />
          </Div>
        </Div>
      </NavStyled>
      <ModalComponent
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title='Logout'
      ></ModalComponent>
    </>
  );
};
