import { H1, H2, P1 } from '../../../styles/FontStyles';
import { colors } from '../../../styles/GlobalStyles';
import { GradientText, Main, Section1Wrapper } from '../Home.styled';

import image1 from '../../../assets/images/section1Image1.png';
import { Div, Img } from '../../../styles/Styles';
import CountdownComponent from './Countdown';

export const Section1 = () => {
  return (
    <Section1Wrapper>
      <div id='section1'>
        <Div $m='750px 0 100px 0' $mSM='600px 0 100px 0'>
          <GradientText>
            <H1 mb='35' mbSM='10' center>
              Uniting Blockchains Connecting Possibilities
            </H1>
          </GradientText>
          <H2 center color={colors.white}>
            Bridging Web3 - Unlocking Limitless Potential
          </H2>
        </Div>
        {/* <Section1Image1 src={image1} alt='all in one' /> */}
      </div>
      <Main id='section2'>
        <CountdownComponent />
      </Main>
      <Main>
        <Div $flex $justifyCenter $center $columnSM $gap='100px' $gapSM='20px'>
          <Div $width='30%' $widthSM='100%' $flex $center>
            <Img $width='100%' $widthSM='70%' src={image1} alt='who we are' />
          </Div>
          <Div $width='70%' $widthSM='100%' $flex $column>
            <GradientText>
              <H2 centerSM mb='20' mt='24' color={colors.primary}>
                Akasha is a Layer Zero blockchain.
              </H2>
            </GradientText>
            <P1 centerSM color={colors.white}>
              Akasha connects different blockchains, allowing applications to
              transfer data, assets and messages between networks. It provides a
              reliable and efficient framework for seamless cross-chain
              communication, ensuring secure and verifiable interactions without
              relying on intermediaries. Akasha expands the possibilities for
              blockchain interoperability, enabling a more connected and
              scalable ecosystem.
            </P1>
          </Div>
        </Div>
      </Main>
    </Section1Wrapper>
  );
};
