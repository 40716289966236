import styled from 'styled-components';
import background from '../../../assets/images/litepaperBackground.jpg';

export const LitepaperStyled = styled.div<{ redBorder?: boolean }>`
  background-image: url(${background});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  width: 100%;
  /* background: #151519; */
  border: 1.75px solid #909090;
  backdrop-filter: blur(5.894180774688721px);

  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  margin-bottom: 20px;
  padding: 20px 25px;
  cursor: pointer;

  @media (min-width: 3500px) {
    padding: 40px 50px;
    svg {
      transform: scale(2);
    }
  }

  @media (max-width: 1000px) {
    padding: 16px 20px;
  }

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  standard-user-select: none;
`;

export const Collapsable = styled.div<{
  expanded: boolean;
  elementHeight: number;
}>`
  opacity: ${(props) => (props.expanded ? '1' : '0')};
  visibility: ${(props) => (props.expanded ? 'visible' : 'hidden')};
  max-height: ${({ expanded, elementHeight }) =>
    expanded ? `${elementHeight}px` : '0px'};
  margin-top: ${(props) => (props.expanded ? '20px' : '0px')};
  overflow: hidden;
  transition: all 300ms;

  @media (min-width: 3500px) {
    margin-top: ${(props) => (props.expanded ? '40px' : '0px')};
  }

  @media (max-width: 800px) {
    margin-top: ${(props) => (props.expanded ? '10px' : '0px')};
  }
`;

export const ChevronStyle = styled.div<{ expanded: boolean }>`
  width: 24px;
  height: 24px;

  transform: ${(props) => (props.expanded ? 'rotate(-180deg)' : '0px')};
  transition: 0.3s ease;
  flex: none;
  @media (max-width: 800px) {
    width: 20px;
    height: 20px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
`;
