import styled from 'styled-components';
import background from '../../assets/images/resourcesBackground.jpg';
import background2 from '../../assets/images/resourcesBackground2.jpg';

export const Section1Wrapper = styled.section`
  width: 100%;
  background-image: url(${background});
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 200px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;

  @media (max-width: 1000px) {
    padding: 150px 0;
  }
`;

export const Section3Wrapper = styled.section`
  width: 100%;
  background-image: url(${background2});
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;

  @media (max-width: 1000px) {
    background-image: url(${background});

    padding: 50px 0;
  }
`;

export const Section2Wrapper = styled.section`
  width: 100%;
  background-image: url(${background});
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 150px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* min-height: 100vh; */

  @media (max-width: 1000px) {
    padding: 50px 0;
  }
`;

export const Section5Wrapper = styled.section`
  width: 100%;
  background-image: url(${background});
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* min-height: 100vh; */

  @media (max-width: 1000px) {
    padding: 50px 0;
  }
`;
