import ReactDOM from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import App from './App';
import { StyledToastContainer } from './components/Toasts/ToastsStyled';
import './index.css';
import { GlobalStyles, chooseTheme } from './styles/GlobalStyles';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <>
    <ThemeProvider theme={chooseTheme}>
      <GlobalStyles />
      <StyledToastContainer />
      <App />
    </ThemeProvider>
  </>
);
