// eslint-disable-next-line no-unused-vars

import { Home } from 'pages';
import { PrivacyPolicy } from 'pages/PrivacyPolicy/PrivacyPolicy';
import { Resources } from 'pages/Resources/Resources';
import { TermsAndConditions } from 'pages/TermsAndConditions/TermsAndConditions';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { PRIVACY_POLICY, TERMS_AND_CONDITIONS } from 'routes/routes';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/resources' element={<Resources />} />

        <Route path={PRIVACY_POLICY} element={<PrivacyPolicy />} />
        <Route path={TERMS_AND_CONDITIONS} element={<TermsAndConditions />} />
      </Routes>
    </Router>
  );
}

export default App;
