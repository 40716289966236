import { Main } from 'pages/Home/Home.styled';
import { colors } from 'styles';
import { H3 } from '../../styles/FontStyles';
import { Div } from '../../styles/Styles';

export const PrivacyPolicy = () => {
  return (
    <Main style={{ margin: '50px 0' }}>
      <Div $flex $column $center>
        <H3 center color={colors.primary} mb='40' mbSM='20'>
          Privacy Policy
        </H3>

        <p>
          We, Travelution Solutions L.L.C., Inc., with its address at Al A'amal
          street, Ontario Tower, Business Bay, Dubai, United Arab Emirates
          and&#xa0; (“Travelution”, “we”, “us”, or “our”), are the provider of a
          comprehensive travel application that helps users discover and
          experience the best adventures, design the perfect itinerary, and
          access other exciting travel-related features. Our app utilizes
          advanced AI technology to assist you in planning your trips, offering
          personalized recommendations based on your preferences, and making it
          easier than ever to organize your ideal travel experience. Whether
          you’re exploring a new city or revisiting a favorite destination,
          Travelution helps you connect with cities and discover top attractions
          effortlessly.
        </p>
        <p>
          This policy concerns our privacy and data collection, processing and
          transfer practices&#xa0; (the&#xa0;<strong>“Privacy Policy”</strong>)
          and describes how we treat the information we collect when you visit
          and use the website available at&#xa0;&#xa0;
          <a
            href='https://travelution.ai/terms-of-service'
            // style='text-decoration:none'
          >
            <span className='Hyperlink'>
              https://travelution.ai/terms-of-service
            </span>
          </a>{' '}
          (the&#xa0;<strong>“Website”</strong>) and Travelution’s other domains,
          products, services, mobile applications, and content (collectively
          with the Site, the&#xa0;<strong>“Services”</strong>). By accessing our
          Services, you are acknowledging that this Privacy Policy and our Terms
          of Service available
          at:&#xa0;https://travelution.ai/terms-of-service&#xa0;(the “
          <strong>Terms of Service</strong>”) apply to your use of the Services
          and that you agree to be bound by this Policy and our Terms of
          Service.&#xa0;
        </p>
        <p>
          Travelution<strong>&#xa0;</strong>is a controller for the personal
          information processed under this policy and therefore is responsible
          for your personal information processing. All changes we make to our
          privacy policy in the future will be posted on our Website. Please
          check back frequently to see any updates or changes to our privacy
          policy.
        </p>
        <p>
          <strong>This policy was last updated in November 2024.</strong>
        </p>
        <p>
          <strong>
            Section 1: Personal Information We Collect and Process
          </strong>
          <strong>&#xa0;</strong>
        </p>
        <p>
          <strong>1.)</strong>&#xa0;We may process the following personal
          information to:
        </p>
        <p>
          <strong>a)</strong>&#xa0;Provide our Services, including access to
          Services, support services and updates. This also includes the usage
          of essential cookies for the facilitation of our Website. We process
          your personal information for this purpose under performance of the
          contract,
        </p>
        <p>
          <strong>b)</strong>&#xa0;Deliver direct marketing messages, travel
          offers, or advertisements to your email or other indicated channels.
          We process your personal information for this purpose under your
          consent.
        </p>
        <p>
          <strong>c)</strong>&#xa0;Process your request through our Website
          and/or Services including, but not limited to, contacting us or
          applying for an open job vacancy,
        </p>
        <p>
          <strong>d)</strong>&#xa0;Optimise our Website and Services to
          continuously improve our Service. We process your personal information
          for this purpose under consent,&#xa0;
        </p>
        <p>
          <strong>e)</strong>&#xa0;Deliver content to you that we believe you
          would be interested in based on your previous interactions with
          Travelution. We process your personal information for this purpose
          under legitimate interest. More information can be found in 1.2,
          Provide relevant notifications to you based on your usage of the
          Services, or for emergency situations.
        </p>
        <p>
          <strong>f)</strong>&#xa0;Fulfill our legal obligations and defend
          claims in the case of proceedings before public authorities. We
          process your personal information for this purpose to stay in
          compliance with our legal obligations.&#xa0;<strong>&#xa0;</strong>
        </p>
        <p>
          <strong>g)</strong>&#xa0;Travel Information: We may collect and
          process travel-related information such as your travel itineraries,
          bookings, preferences, and destination information. This information
          may be used to personalize your experience, recommend travel options,
          and optimize your travel arrangements.
        </p>
        <p>
          <strong>h)</strong>&#xa0;Geolocation information: For enhanced travel
          experience, we may collect and process your geolocation information to
          provide location-based recommendations, updates, and services. You can
          manage your geolocation settings through your device preferences.
        </p>
        <p>
          <strong>2.)</strong>&#xa0;If you have used our Service in the past and
          provided us with your email address, we may contact you at this email
          address to promote our Services, special offers, news and provide
          other information related to our Services via our newsletter. However,
          you have the option to choose not to receive our newsletters by
          ticking the appropriate check box when submitting your email. You may
          also unsubscribe from our newsletter at any time by ticking the
          appropriate check box or by using the link in the newsletter.
        </p>
        <p>
          <strong>a)</strong>
          <strong>&#xa0;</strong>We retain travel-related information for as
          long as necessary to provide you with services and comply with our
          legal obligations, or until you request its deletion
        </p>
        <p>
          <strong>b)</strong>&#xa0;We may profile your travel preferences and
          past interactions to personalize offers and content, such as
          suggesting destinations or travel services tailored to your interests.
        </p>
        <p>
          <strong>
            Section 2: Personal information categories that we process may
            include your:
          </strong>
        </p>
        <p>
          <strong>a)</strong>&#xa0;personal details (including your name,
          surname, date of birth),
        </p>
        <p>
          <strong>b)</strong>&#xa0;contact details (including your address,
          phone number, email address),
        </p>
        <p>
          <strong>c)</strong>&#xa0;professional details (including your name of
          the company/city/town/municipality, that you work for and any other
          information you may provide about your role),personal information you
          provide in communication via the Services, Website, telephone, email
          or otherwise by you.
        </p>
        <p>
          <strong>d)</strong>&#xa0;The content you provide to the Services: The
          Travelution Services are based directly upon content that users
          provide when using the Services, including travel-related issues or
          feedback. This content may include information such as your name,
          travel preferences, and feedback on services. If relevant,
          location-based services such as geolocation might be used to enhance
          your travel experience. In many cases, the default physical location
          of the issue is generated from obtaining GPS or other geolocation
          information directly from the user’s computer or mobile device.&#xa0;
          <strong>e)</strong>&#xa0;It is also possible that user-provided
          content may contain identifying information about other people and the
          user including unregistered users. For example, a user might include a
          name or identifying information about a person or property in the
          textual description of an issue report. Or a user might include an
          image, a video, or a voice recording of another person as part of an
          issue report.
        </p>
        <p>
          <strong>Section 3: Other information</strong>&#xa0;that we obtain from
          you when you use our Website and Services include:
        </p>
        <p>
          <strong>a)</strong>&#xa0;
          <strong>Technical information on our Website and Services</strong>,
          including, where applicable, the Internet Protocol (IP) address used
          to connect your computer to the Internet, your login credentials,
          specific online identifiers (for example Apple ID), browser type and
          version, time zone setting, browser plug-in types and versions,
          operating system and platform, image metadata, information about your
          visit, including your clickstream to, through and from our website
          (including date and time), page response times, download errors,
          length of visits to certain pages, page interaction information (such
          as scrolling, clicks, and mouse-overs), methods used to browse away
          from the page, number and frequency of our visitors, which pages or
          features of the Services they have visited, which links on the
          Services they have clicked on, and the length of those visits. We may
          also use third-party applications and services, as well as in house
          systems to collect and analyze this information.
        </p>
        <p>
          <strong>b)</strong>&#xa0;Without collecting the IP address from you
          and processing it, we cannot connect you to the nearest server, which
          is necessary for the provision of our Services (it means that we do
          not permanently store the IP address, and it is only used to find the
          place where the server answer of your request should be sent to). The
          IP address is stored in our system only in an anonymized form.
        </p>
        <p>
          <strong>c)</strong>&#xa0;We use all technical information in aggregate
          form, that is, as a statistical measure related to all of our users
          that would not identify you personally.
        </p>
        <p>
          <strong>d)</strong>&#xa0;We collect this information to improve the
          Services and provide additional features and functionality.
        </p>
        <p>
          <strong>e)</strong>&#xa0;
          <strong>Location of the device using our Services</strong>.
          <strong>&#xa0;</strong>
          <strong>
            When you use our services, we may collect information about your
            location. With your consent, we may also collect information about
            your precise location using methods that include GPS,
          </strong>
          <strong>&#xa0;</strong>wireless networks, cell towers, Wi-Fi access
          points, and other sensors to provide notifications
          <strong>
            . Your location information is not shared with any third party. You
            can withdraw your consent to us tracking your location at any time
            in the application settings.
          </strong>
          <strong>&#xa0;</strong>
        </p>
        <p>
          <strong>f)</strong>
          <strong>&#xa0;</strong>Our use of cookies to process personal
          information is explained in our section on cookies.
        </p>
        <p>
          <strong>g)</strong>&#xa0;Please note that we require the provision of
          personal information required to create your account and to properly
          provide our Services per the Terms and Conditions you have concluded
          with us. In the event of non-provision of this information, we are
          unable to provide our Services properly (for example the use of the
          website as a logged-in user for the use of services you have ordered).
        </p>
        <p>
          <strong>
            Section 4: What third parties do we share your information with?
          </strong>
        </p>
        <p>
          <strong>1.)</strong>
        </p>
        <p>
          a) We have internal policies in place to ensure that your information
          is only shared with authorised personnel at our company or a verified
          third party. Our staff might have access to your personal information
          on a strictly need-to-know basis typically governed and limited by
          function, role and department of the particular employee.
        </p>
        <p>
          b) Please note that we may only disclose your personal information to
          service providers that have undertaken the necessary security measures
          to protect your information. When we share personal information with
          others we require them to keep it safe. In this section, you may learn
          more about who we share your personal information with.
        </p>
        <p>
          <strong>2.)</strong>&#xa0;We may share your personal information with
          the following recipients:
        </p>
        <p>
          <strong>a)</strong>&#xa0;Business Partners, Suppliers, and
          Subcontractors: We may share your information with our business
          partners, suppliers, subcontractors, and service providers who assist
          in providing travel services, such as:Travel-related services:
          Airlines, hotels, car rental companies, travel booking platforms, or
          tour operators, to facilitate travel bookings, reservations, and
          itineraries.
        </p>
        <p>
          <strong>–</strong>&#xa0;IT and Cloud Service Providers: Authorized IT
          service providers who support the infrastructure of our website and
          services.
        </p>
        <p>
          <strong>–</strong>&#xa0; Payment Processors: Companies that process
          payments securely on our behalf to handle bookings, transactions, and
          refunds.
        </p>
        <p>
          <strong>–</strong>
          <strong>&#xa0;</strong>&#xa0;Accounting and Legal Services: Companies
          providing accounting services to manage travel-related transactions,
          and legal advisers to ensure compliance with relevant regulations. We
          ensure that any subcontractors or third-party service providers comply
          with GDPR by ensuring the technical and organizational security of
          processing operations.
        </p>
        <p>
          <strong>b) Advertising and Analytics Partners:</strong>
          <br />
          We may share your information with advertising platforms, analytics
          providers, or similar services that help us improve our travel-related
          services, optimize user experience, and deliver personalized travel
          content and advertisements. For example, we may collaborate with
          marketing platforms to tailor travel offers based on your preferences
          or past interactions.
        </p>
        <p>
          <strong>c)</strong>&#xa0;Analytics and search engine providers may
          also assist us in improving and optimizing our website and mobile
          applications, helping us provide you with relevant travel
          recommendations.
        </p>
        <p>
          <strong>d)</strong>&#xa0;<strong>Third-party Travel Services:</strong>
          <strong>&#xa0;</strong>When booking through third-party travel
          services, your personal information (such as your name, booking
          details, or payment information) may be shared with airlines, hotels,
          car rental agencies, or travel insurance companies to facilitate your
          bookings and other travel arrangements.
        </p>
        <p>
          <strong>
            e) Auditors, Legal Advisers, and Other Professional Consultants:
          </strong>
          <strong>&#xa0;</strong>
          <strong>&#xa0;</strong>
          <strong>&#xa0;</strong>
          <strong>&#xa0;</strong>
        </p>
        <p>
          In some specific situations, such as audits, legal compliance, or
          dispute resolution, we may share your personal information with
          auditors, legal advisers, or other consultants. This is done only for
          purposes relevant to compliance with legal obligations, defending
          claims, or consulting on business strategy.
        </p>
        <p>
          <strong>f) Travel-related Partners:</strong>&#xa0;We may collaborate
          with partners to offer travel-related content or promotions. For
          instance, we may share anonymized information with travel content
          creators or destination marketing organizations to improve our service
          offerings or provide you with travel recommendations.
        </p>
        <p>
          <strong>g)</strong>&#xa0;Our Website and Services may contain links
          provided by services from third parties. While we try to facilitate
          access only to those third party services that share our respect for
          your privacy, we don’t take responsibility for the content or privacy
          policies of those third party services. We encourage you to carefully
          review the privacy policies of all third party services you
          access.&#xa0;
        </p>
        <p>
          <strong>h)</strong>
          <strong>&#xa0;</strong>We may use screen recording software that
          tracks user interactions within our application by recording sessions
          in the background. All personal information and user text inputs are
          not recorded. Any personal information captured during these
          recordings are automatically blurred to protect your privacy. These
          recordings are retained for a maximum period of 30 days and are then
          permanently deleted.”
        </p>
        <p>
          <strong>Section 5: How do we protect your informations?</strong>
        </p>
        <p>
          <strong>a)</strong>&#xa0;We take security measures to protect your
          personal information processed in hard copies or/and electronically as
          well from destruction, loss, alteration, unauthorized provision or
          access to them.
        </p>
        <p>
          <strong>b)</strong>&#xa0;We have appropriate technical and
          organizational measures in place, in accordance with the relevant
          information protection legislation in order to secure our website and
          as well as other systems used for information processing including
          SSL, HTTPS, and TLS encryption.
        </p>
        <p>
          <strong>c)</strong>&#xa0;Our website may contain links to other
          websites or services of different providers than us. We are not
          responsible for the content and provision of websites or services of
          these different providers. This Privacy Policy does not apply to the
          processing of personal information during browsing or using websites
          or services of said different providers.
        </p>
        <p>
          <strong>d)</strong>&#xa0;You are responsible for maintaining the
          confidentiality of your access information. Therefore, we suggest that
          you do not disclose your access information to anyone. You should
          treat your access information as confidential and always remember to
          log out of your account after you end your activity.
        </p>
        <p>
          <strong>Section 6: How do we store your informations?</strong>
        </p>
        <p>
          <strong>a)</strong>&#xa0;Regardless of where you use our Services or
          otherwise provide information to us, your personal information may be
          transferred to and maintained on servers located in the United States
          or elsewhere.
        </p>
        <p>
          <strong>b)</strong>&#xa0;If you are a European resident, in the event
          that&#xa0; information is transferred to the United States we use
          Standard Contractual Clauses and adequacy decisions to ensure personal
          information protection is maintained at the same standard as in
          Europe.&#xa0;
        </p>
        <p>
          <strong>Section 7: How do we use cookies?</strong>
          <strong>&#xa0;</strong>
        </p>
        <p>
          <strong>1.) Cookies</strong>
        </p>
        <p>
          <strong>a) Cookies:</strong>&#xa0;Cookies are small pieces of text
          that may be sent to and stored by your web browser when you access a
          website. Your web browser stores these cookies in a way that is
          associated with each website you visit, and you can view and manage
          these cookies through your browser settings.
        </p>
        <p>
          <strong>b)</strong>&#xa0;<strong>Travelution</strong>, now a
          travel-focused app, uses both essential and non-essential cookies to
          provide, personalize, and improve our travel services. Here’s how we
          use them:
        </p>
        <p>
          <strong>c)</strong>&#xa0;<strong>Essential Cookies:</strong>
          &#xa0;Travelution uses essential cookies to enable core
          functionalities required to provide our travel services, such as
          enabling access to the site, processing bookings, or securing your
          session. These cookies are necessary for the functioning of the
          service and cannot be refused. Without them, key travel-related
          features, such as booking management or secure payment processing, may
          not function correctly.
        </p>
        <p>
          <strong>2.)</strong>&#xa0;<strong>Non-Essential Cookies:</strong>
          &#xa0;We use non-essential cookies to recognize your web browser,
          provide personalized travel recommendations, and continuously improve
          our services. Non-essential cookies are only used with your consent
          and help us enhance your overall travel experience by, for example,
          showing you relevant travel destinations, itineraries, or promotions
          based on your past interactions. The non-essential cookies we use
          include:
        </p>
        <p>
          <strong>a) Google Analytics:</strong>&#xa0;To understand user behavior
          and preferences, we use Google Analytics cookies to track your
          interactions with our website and travel services. This helps us
          improve our offerings by analyzing user trends, optimizing travel
          recommendations, and tailoring content to your needs
        </p>
        <p>
          <strong>b)</strong>&#xa0;<strong>Facebook:</strong>&#xa0;We use
          Facebook cookies for remarketing and to serve you relevant
          travel-related advertisements based on your interests. If you interact
          with our travel content on Facebook, these cookies may be used to show
          you similar content or offers in the future.
        </p>
        <p>
          <strong>c)</strong>&#xa0;<strong>Hotjar:</strong>&#xa0;Hotjar cookies
          help us understand how users navigate and interact with our website.
          This allows us to optimize the user experience and improve our travel
          service interface, making it easier for you to search for
          destinations, book trips, or find relevant travel resources.
        </p>
        <p>
          <strong>d)</strong>&#xa0;<strong>HubSpot:</strong>&#xa0;We use HubSpot
          cookies to track user engagement and interactions with our site,
          particularly for customer support and travel-related inquiries. This
          helps us offer more personalized assistance and respond to your needs
          efficiently.
        </p>
        <p>
          <strong>3.) Web Beacons</strong>&#xa0;
        </p>
        <p>
          In addition to cookies, we may use web beacons, which are small,
          invisible images placed in emails. These allow us to detect when you
          open our emails and help us measure the deliverability and
          effectiveness of our travel-related newsletters, promotions, or
          updates. For instance, we may use web beacons to track whether you
          opened an email with a special offer for a travel destination,
          allowing us to better tailor future communications.
        </p>
        <p>
          <strong>Section 8: General</strong>
        </p>
        <p>
          <strong>a) Privacy of Children</strong>
          <strong>&#xa0;</strong>Our Site and Services are not intended for use
          by children 13 years of age and younger, so you must be at least 14
          years of age to use the Services. Accordingly, we do not knowingly
          collect information from or direct the Services to anyone under 14
          years of age. We encourage parents and legal guardians to monitor
          their children’s mobile app and Internet usage and enforce our Privacy
          Policy by instructing their children never to provide Personal
          Information to&#xa0; Travelution Services without their parental
          permission. If you have reason to believe that a child under the age
          of 14 has provided Personal Information to us, please contact us
          at&#xa0;{' '}
          <a href='mailto:office@travelution.ai'>office@travelution.ai</a>, and
          we will delete that information from our databases as soon as is
          reasonably practicable.&#xa0;
        </p>
        <p>
          <strong>
            Section 9: What are your rights concerning your privacy?
          </strong>
        </p>
        <p>
          <strong>1.)</strong>&#xa0;&#xa0; If you would like to exercise any of
          your rights or receive more information about them, please contact us
          at <a href='mailto:office@travelution.ai'>office@travelution.ai</a> .
        </p>
        <p>
          <strong>2.)</strong>&#xa0;Regarding your processed personal
          information, you have the following rights:
        </p>
        <p>
          <strong>a) Right of access</strong>
          <strong>&#xa0;</strong>You have the right to gain access to
          information about the personal information that we process about you.
          Should you have any questions regarding the processing, or you would
          like to have more insight about the personal information we process
          from you, please contact us via the contact details below and we will
          provide you with further information.
        </p>
        <p>
          <strong>b)</strong>&#xa0;<strong>Right to rectification</strong>
          <strong>&#xa0;</strong>You can request from us to correct information
          inaccurately stored by us without undue delay. You also have the right
          to have incomplete personal information completed, including by means
          of providing a supplementary statement.
        </p>
        <p>
          <strong>c)</strong>&#xa0;
          <strong>Right to erasure/right to be forgotten</strong>
          <strong>&#xa0;</strong>You have the right to request us to permanently
          delete your personal information. You can make such a request if you
          for example believe that the personal information are no longer
          necessary in relation to the purpose for which the personal
          information were collected or otherwise processed.
        </p>
        <p>
          <strong>d)</strong>&#xa0;
          <strong>Right to restrict the processing activities</strong>
          <strong>&#xa0;</strong>You have the right to request from us to
          restrict our processing activities, for example, if you believe we
          process incorrect personal information about you, for the time being
          until the correctness of that personal information is verified, or
          when the processing of your personal information is unlawful.
        </p>
        <p>
          <strong>e)</strong>&#xa0;<strong>Right to data portability</strong>
          <strong>&#xa0;</strong>You have the right to request data that we
          process about you by automated means based on a contract or consent in
          a structured, commonly used and machine-readable format, and you have
          the right to request that we transmit those data to another
          controller, if technically feasible.
        </p>
        <p>
          <strong>f)</strong>&#xa0;
          <strong>Right to complain to a supervisory authority</strong>
          <strong>&#xa0;</strong>If you are not satisfied with the method of how
          we process your personal information, you can contact us at any time
          to find a solution for your concern.&#xa0;
        </p>
        <p>
          <strong>
            Section 10: CCPA (California Consumer Privacy Act of 2018)
          </strong>
          <strong>&#xa0;</strong>
        </p>
        <p>
          <strong>1.)</strong>&#xa0;Under the California&#xa0;
          <strong>Consumer Privacy Act of 2018 (the “CCPA”)</strong>, California
          residents are entitled to ask us for a notice identifying the
          categories of personal customer information that we share with our
          affiliates and/or third parties for marketing purposes, and providing
          contact information for such affiliates and/or third parties. If you
          are a California resident and would like a copy of this notice, please
          submit a request to the following address:{' '}
          <a href='mailto:office@travelution.ai'>office@travelution.ai</a>.
        </p>
        <p>
          <strong>2.)</strong>&#xa0;California residents are entitled to the
          above rights. If you would like to exercise any of your rights or
          receive more information about them, please contact us at{' '}
          <a href='mailto:office@travelution.ai'>office@travelution.ai</a>
          .&#xa0;
        </p>
        <p>
          <strong>Section 11: How can you contact Us?1.)</strong>
          <strong>&#xa0;</strong>If you have any questions or concerns regarding
          privacy using the Services, please send us a&#xa0; detailed message at{' '}
          <a href='mailto:office@travelution.ai'>office@travelution.ai</a>.
        </p>
        <p>&#xa0;</p>
      </Div>
    </Main>
  );
};
