import { Dispatch, ReactNode, SetStateAction, useRef } from 'react';
import { ChevronIcon, Div, H3, H4, P1, P2, lightTheme } from 'styles';
import { ChapterWrapper, GradientText } from '../Home.styled';
import { ChevronStyle, Collapsable, LitepaperStyled } from './Litepaper.styled';

interface LitepaperProps {
  children: ReactNode;
  title: string;
  index: number;
  expanded: number | null;
  setExpanded: Dispatch<SetStateAction<number | null>>;
}

export const Litepaper = ({
  children,
  title,
  index,
  expanded,
  setExpanded,
}: LitepaperProps) => {
  const ref = useRef<any>(null);
  const elementHeight = ref?.current?.clientHeight;

  return (
    <LitepaperStyled
      onClick={() => setExpanded(expanded === index ? null : index)}
    >
      <Div $desktop>
        <Div $flex $alignCenter $between $gap='20px'>
          <Div $flex $alignCenter $gap='20px'>
            <ChapterWrapper>
              <GradientText>
                <P2>Chapter {index}</P2>
              </GradientText>
            </ChapterWrapper>
            <H4 m='0' color={lightTheme.white}>
              {title}
            </H4>
          </Div>

          <ChevronStyle expanded={expanded === index}>
            <ChevronIcon />
          </ChevronStyle>
        </Div>
      </Div>

      <Div $mobile>
        <Div>
          <Div $flex $alignCenter $between $gap='20px' $mb='10px'>
            <ChapterWrapper>
              <GradientText>
                <P1>Chapter {index}</P1>
              </GradientText>
            </ChapterWrapper>

            <ChevronStyle expanded={expanded === index}>
              <ChevronIcon />
            </ChevronStyle>
          </Div>

          <H3 m='0' color={lightTheme.white}>
            {title}
          </H3>
        </Div>
      </Div>
      <Collapsable expanded={expanded === index} elementHeight={elementHeight}>
        <div ref={ref}>{children}</div>
      </Collapsable>
    </LitepaperStyled>
  );
};
