import { Main } from 'pages/Home/Home.styled';
import { Div } from '../../styles/Styles';
import DocViewer from 'react-doc-viewer';

export const TermsAndConditions = () => {
  const doc = [{ uri: '/Privacy policy_travelution.docx' }];

  return (
    <Main style={{ margin: '50px 0' }}>
      <DocViewer documents={doc} />
    </Main>
  );
};
