import { H1, P1, colors } from 'styles';
import { GradientText, Main, Section3Wrapper } from '../Home.styled';

export const Section3 = () => {
  return (
    <Section3Wrapper>
      <Main>
        <GradientText>
          <H1 center mb='30'>
            Utility Of Akasha
          </H1>
        </GradientText>
        <P1 center color={colors.white}>
          In decentralized ecosystems, a cryptocurrency coin powers
          transactions, enabling value transfers and incentivizing
          participation. Through smart contracts, it automates processes,
          ensures transparency, and rewards contributors, fostering community
          engagement.
          <br />
          <br />
          As the backbone of the ecosystem, it enhances efficiency, creates
          economic opportunities, and drives innovation and growth in the
          digital landscape.
        </P1>
      </Main>
    </Section3Wrapper>
  );
};
