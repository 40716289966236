import Modal from 'react-modal';
import { CloseIcon, Div, H3, colors } from 'styles';

interface ModalProps {
  isOpen: boolean;
  setIsOpen: any;
  children?: any;
  title?: string;
}

export const ModalComponent = ({
  children,
  title,
  isOpen,
  setIsOpen,
}: ModalProps) => {
  const customStyles = {
    content: {
      borderRadius: '30px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      background: '#131111',
      width: '90%',
      maxWidth: '800px',
      border: 'none',
    },
    overlay: {
      background: colors.black + 80,
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      style={customStyles}
      ariaHideApp={false}
    >
      <Div $column>
        {/* <Div
          $flex
          $column
          $alignEnd
          onClick={() => setIsOpen(false)}
          style={{ cursor: 'pointer' }}
        >
          <CloseIcon />
        </Div> */}
        <Div $flex $column $center>
          {title && <H3 color={colors.white}>{title}</H3>}
        </Div>
        {children}
      </Div>
    </Modal>
  );
};
