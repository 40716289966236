import { SuccessToast } from 'components/Toasts/Toasts';
import React, { useEffect } from 'react';

export const navLinks = [
  { path: '#section1', name: 'Home' },
  { path: '#section2', name: 'About' },
  { path: '#section3', name: 'Partners' },
  { path: '#section4', name: 'Litepaper' },
  { path: '/resources', name: 'Resources' },
  // { path: '#section4', name: 'Safety' },
];

export const pathname = window.location.pathname;

export const useOnClickOutside = (ref: any, handler: any) => {
  useEffect(() => {
    const listener = (event: any) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

export const decodeJwt = (token: string) => {
  const parts = token.split('.');
  if (parts.length !== 3) {
    throw new Error('Invalid JWT format');
  }

  const payload = JSON.parse(atob(parts[1]));
  return payload;
};

export const packagesData = [
  {
    id: 1,
    benefits: [
      'Virtual (KYC)',
      '1% Cashback (with $300 ULX in staking)',
      'Monthly Cashback Cap: $500 in ULX',
      'Monthly Spending Limit: $1,500',
      'Supports Apple Pay & Google Pay',
      'Global Acceptance except for restricted countries',
    ],
  },
  {
    id: 2,
    benefits: [
      'Virtual (KYC)',
      '1% Cashback (with $300 ULX in staking)',
      'Monthly Cashback Cap: $500 in ULX',
      'Monthly Spending Limit: $1,500',
      'Supports Apple Pay & Google Pay',
      'Global Acceptance except for restricted countries',
    ],
  },
  {
    id: 3,
    benefits: [
      'Virtual (KYC)',
      '1% Cashback (with $300 ULX in staking)',
      'Monthly Cashback Cap: $500 in ULX',
      'Monthly Spending Limit: $1,500',
      'Supports Apple Pay & Google Pay',
      'Global Acceptance except for restricted countries',
    ],
  },
  {
    id: 4,

    benefits: [
      'Virtual (KYC)',
      '1% Cashback (with $300 ULX in staking)',
      'Monthly Cashback Cap: $500 in ULX',
      'Monthly Spending Limit: $1,500',
      'Supports Apple Pay & Google Pay',
      'Global Acceptance except for restricted countries',
    ],
  },
];

export const copyLink = (link?: string, message?: string) => {
  if (navigator.clipboard) {
    navigator.clipboard
      .writeText(link || '')
      .then(() => SuccessToast(message || 'Link has been copied'))
      .catch((err) => console.error('Failed to copy: ', err));
  } else {
    const textArea = document.createElement('textarea');
    textArea.value = link || '';
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    SuccessToast(message || 'Link has been copied');
  }
};
export const renderPillColor = (s: string | undefined) => {
  switch (s) {
    case 'paid':
      return '#A5DDAF';

    case 'pending':
      return '#A5B1DD';

    case 'canceled':
      return '#FF7474';

    default:
      return '';
  }
};

export const renderTextColor = (s: string | undefined) => {
  switch (s) {
    case 'paid':
      return '#155A24';

    case 'pending':
      return '#15165A';

    default:
      return '';
  }
};

export const handleSmoothScroll = (
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  path: string,
  location: { pathname: string },
  navigate: (path: string) => void
) => {
  if (path.startsWith('#')) {
    // It's a section ID, prevent default and scroll smoothly
    event.preventDefault();
    const targetId = path.replace('#', '');
    const targetElement = document.getElementById(targetId);

    if (location.pathname !== '/') {
      // Navigate to homepage and then scroll to section
      navigate(`/#${targetId}`);
      setTimeout(() => {
        const newTargetElement = document.getElementById(targetId);
        if (newTargetElement) {
          newTargetElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      }, 100);
    } else {
      // Directly scroll if already on the homepage
      if (targetElement) {
        window.history.pushState({}, '', path);
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  } else {
    // ✅ Normal navigation for non-section links like "/resources"
    navigate(path);
  }
};

export const validateEmail = (mail: string) => {
  var validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (mail.match(validRegex)) {
    return true;
  } else {
    return false;
  }
};

export const openPdfInNewWindow = (pdfUrl: string) => {
  const newWindow = window.open(pdfUrl, '_blank');
  if (newWindow) {
    newWindow.focus();
  } else {
    alert(
      'Unable to open PDF. Please disable your pop-up blocker and try again.'
    );
  }
};
