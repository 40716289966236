export const ROOT = `/`;

export const DASHBOARD = '/dashboard';

export const PROFILE = 'profile';

export const ORDERS = 'orders';
export const EDIT_PROFILE = 'edit-profile';

export const CART = 'cart';

export const DASHBOARD_CART = `${DASHBOARD}/${CART}`;

export const DASHBOARD_PROFILE = `${DASHBOARD}/${PROFILE}`;
export const DASHBOARD_PROFILE_ORDERS = `${DASHBOARD}/${PROFILE}/${ORDERS}`;
export const DASHBOARD_PROFILE_EDIT_PROFILE = `${DASHBOARD}/${PROFILE}/${EDIT_PROFILE}`;

export const DASHBOARD_ORDERS = `${DASHBOARD}/${PROFILE}`;

export const THANK_YOU = `${DASHBOARD}/thank-you`;

export const SHIPPING_BILLING = `${DASHBOARD}/shipping-billing`;

export const PAYMENT_SUMMARY = `${DASHBOARD}/payment-summary/:id`;

export const AUTH = '/auth';
export const SIGNIN = `${AUTH}/signin`;
export const SIGNUP = `${AUTH}/signup`;
export const GOOGLE_COMPLETE = `${AUTH}/google-complete`;
export const RESET_PASSWORD = `${AUTH}/reset-password`;

export const PRIVACY_POLICY = '/privacy-policy';
export const TERMS_AND_CONDITIONS = '/terms-and-conditions';
