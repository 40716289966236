import { InputComponent } from 'components/Input/Input';
import Spinner from 'components/Spinner/Spinner';
import { ErrorToast, SuccessToast } from 'components/Toasts/Toasts';
import { GradientText } from 'pages/Home/Home.styled';
import { useState } from 'react';
import { EmailIcon, P3 } from 'styles';
import { colors } from 'styles/GlobalStyles';
import logo from '../../assets/images/akashaLogoWhite.png';
import { Div } from '../../styles/Styles';
import { FooterStyled, FooterText, SubButtonStyled } from './Footer.styled';

export const Footer = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const SubButton = () => {
    return (
      <SubButtonStyled type='submit'>
        <EmailIcon />
      </SubButtonStyled>
    );
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!email) return ErrorToast('Please enter an email.');
    setLoading(true);

    try {
      const response = await fetch(
        'https://faas-lon1-917a94a7.doserverless.co/api/v1/web/fn-a908fd3e-12ab-4d65-9ced-e97aeaeade9a/default/akasha-mailing-list',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        }
      );

      setLoading(false);

      if (response.ok) {
        SuccessToast('Subscribed successfully!');
        setEmail('');
      } else {
        const data = await response.json();
        ErrorToast(`Error: ${data.message || 'Subscription failed'}`);
      }
    } catch (error: any) {
      ErrorToast(`Network Error: ${error.message}`);
      setLoading(false);
    }
  };

  return (
    <FooterStyled>
      {loading && <Spinner fullscreen={true} transparent />}
      <Div
        $flex
        $between
        $alignCenter
        $columnSM
        $gap='30px'
        $gapSM='12px'
        $reverseSM
      >
        <FooterText>2025 Akasha. All rights reserved.</FooterText>
        <img src={logo} alt='Akasha' />
        {/* <a href='https://t.me/AkashaLifeAK1111'>
          <TelegramIcon />
        </a> */}
        <Div $width='100%' $maxWidth='300px'>
          <GradientText>
            <P3 centerSM> Subscribe to Our Newsletter</P3>
          </GradientText>
          <P3 centerSM color={colors.white}>
            {' '}
            for the latest Akasha updates.
          </P3>
          <form onSubmit={handleSubmit}>
            <InputComponent
              type='email'
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
              placeholder='Enter Email'
              Icon={SubButton}
            />
          </form>
        </Div>
      </Div>
    </FooterStyled>
  );
};
