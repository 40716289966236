import { Div, H2 } from 'styles';
import { GradientText, Main, Section6Wrapper } from '../Home.styled';

export const Section6 = () => {
  return (
    <Section6Wrapper id='section3'>
      <Main>
        <Div $flex $center>
          <GradientText>
            <H2 mb='30' center maxWidth='1200px'>
              Akasha is considered to be the first element created during the
              cosmic evolution and is believed to contain the knowledge of the
              universe.
            </H2>
          </GradientText>
        </Div>
      </Main>
    </Section6Wrapper>
  );
};
