import styled from 'styled-components';

interface TextProps {
  color?: string;
  pointer?: boolean;
  center?: boolean;
  centerSM?: boolean;
  flex?: boolean;
  between?: boolean;
  inline?: boolean;
  alignEnd?: boolean;
  gap?: string;
  underline?: boolean;
  right?: boolean;
  left?: boolean;
  rightSM?: boolean;
  leftSM?: boolean;
  caps?: boolean;
  m?: string;
  mb?: string;
  mt?: string;
  ml?: string;
  mr?: string;
  maxWidth?: string;
  mSM?: string;
  mbSM?: string;
  mtSM?: string;
  mlSM?: string;
  mrSM?: string;
  maxWidthSM?: string;
  bold?: boolean;
  extraBold?: boolean;
  semiBold?: boolean;
  light?: boolean;
  mobile?: boolean;
}

export const DisplayText = styled.div<TextProps>`
  font-weight: 900;
  font-size: 72px;
  line-height: 80px;

  ${({ color }) => color && `color: ${color};`}
  ${({ pointer }) => pointer && `cursor: pointer;`}
  ${({ center }) => center && `text-align: center;`}
  ${({ flex }) => flex && `display: flex;`}
  ${({ between }) => between && `justify-content: space-between;`}
  ${({ inline }) => inline && `display: inline;`}
  ${({ alignEnd }) => alignEnd && `align-items: flex-end;`}
  ${({ gap }) => gap && `gap: ${gap};`}
  ${({ underline }) => underline && `text-decoration: underline;`}
  ${({ right }) => right && `text-align: right;`}
  ${({ left }) => left && `text-align: left;`}
  ${({ caps }) => caps && `text-transform: uppercase;`}

  margin: ${(props) => props.m};
  margin-top: ${(props) => props.mt}px;
  margin-bottom: ${(props) => props.mb}px;
  margin-left: ${(props) => props.ml}px;
  margin-right: ${(props) => props.mr}px;

  max-width: ${(props) => props.maxWidth};

  @media (max-width: 800px) {
    margin: ${(props) => props.mSM};
    margin-top: ${(props) => props.mtSM}px;
    margin-bottom: ${(props) => props.mbSM}px;
    margin-left: ${(props) => props.mlSM}px;
    margin-right: ${(props) => props.mrSM}px;
    ${({ leftSM }) => leftSM && `text-align: left;`}
    ${({ centerSM }) => centerSM && `text-align: center;`}

    max-width: ${({ maxWidthSM }) => maxWidthSM};
  }

  span {
    color: ${({ theme }) => theme.primary};
  }
`;

export const H1 = styled(DisplayText)`
  font-weight: 400;
  font-size: 100px;
  line-height: 110%;

  @media (max-width: 800px) {
    font-size: 40px;
    line-height: 110%;
  }
`;

export const H2 = styled(H1)`
  font-weight: 400;
  font-size: 45px;
  line-height: 110%;
  ${({ bold }) => bold && `font-weight: 700;`}
  ${({ extraBold }) => extraBold && `font-weight: 800;`}

  @media (max-width: 800px) {
    font-size: 25px;
    line-height: 110%;
  }
`;

export const H3 = styled(H2)`
  font-size: 32px;
  line-height: 45px;

  @media (max-width: 800px) {
    font-size: 23px;
    line-height: 110%;
  }
`;

export const H4 = styled(H2)`
  font-size: 25px;
  line-height: 24px;

  @media (max-width: 800px) {
    font-size: 20px;
    line-height: 110%;
  }
`;

export const H5 = styled(H2)`
  font-size: 16px;
  line-height: 20px;
`;

export const P1 = styled(H2)`
  font-weight: 400;
  font-size: 22px;
  line-height: 140%;
  ${({ bold }) => bold && `font-weight: 700;`}
  ${({ semiBold }) => semiBold && `font-weight: 500;`}
  ${({ light }) => light && `font-weight: 300;`}
  @media (max-width: 800px) {
    font-size: 18px;
  }
`;

export const P2 = styled(P1)`
  font-size: 18px;
  @media (max-width: 800px) {
    font-size: 14px;
  }
`;

export const P3 = styled(P1)`
  font-size: 14px;
  @media (max-width: 800px) {
    font-size: 12px;
  }
`;

export const CaptionText = styled(P2)`
  font-size: 12px;

  @media (min-width: 800px) {
    display: ${({ mobile }) => mobile && `none`};
  }
`;

export const FooterText = styled(P2)<any>`
  font-size: 10px;
`;

export const InputLabel = styled(P2)`
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: 700;
`;
