import { H1, P1, colors } from 'styles';
import { GradientText, Section2Wrapper } from '../Home.styled';

export const Section2 = () => {
  return (
    <Section2Wrapper>
      <GradientText>
        <H1 mb='30' centerSM>
          Interconnected Nature Of The Universe
        </H1>
      </GradientText>
      <P1 centerSM color={colors.white} maxWidth='900px'>
        The AKASHA (ether) that permeates the universe. It is considered to be
        the first element created during the cosmic evolution and is believed to
        contain the knowledge of the universe.
      </P1>
    </Section2Wrapper>
  );
};
