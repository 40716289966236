import styled from 'styled-components';
import background from '../../assets/images/section1Background.jpg';
import backgroundMobile from '../../assets/images/section1BackgroundMobile.jpg';
import background2 from '../../assets/images/section2background.jpg';
import background3 from '../../assets/images/section3background.jpg';
import background4 from '../../assets/images/section4background.jpg';
import background6 from '../../assets/images/section6background.jpg';
import { H1 } from '../../styles/FontStyles';

export const Section1Wrapper = styled.section`
  width: 100%;
  background-image: url(${background});
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0 0 200px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1000px) {
    background-image: url(${backgroundMobile});

    padding: 0 0 50px 0;
  }
`;

export const Section2Wrapper = styled.section`
  width: 100%;
  min-height: 150vh;
  background-image: url(${background2});
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 300px 150px;

  @media (max-width: 1000px) {
    padding: 100px 30px;
    min-height: 100vh;
  }
`;

export const TextWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 200px;
  padding: 8px 24px;
`;

export const Section4Wrapper2 = styled.section`
  width: 100%;
  backdrop-filter: blur(105.80000305175781px);
  border: 1px solid #d0d0d0;
  padding: 80px 0;
  background: linear-gradient(
    90deg,
    rgba(0, 6, 10, 0.83) 0%,
    rgba(5, 37, 56, 0.4731) 54.16%,
    rgba(163, 221, 255, 0.249) 100%
  );

  @media (max-width: 1000px) {
    padding: 130px 0;
  }
`;

export const Section3Wrapper = styled.section`
  width: 100%;
  background-image: url(${background3});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  padding: 200px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 800px) {
    min-height: auto;
    padding: 50px 0;
  }
`;

export const Section6Wrapper = styled.section`
  width: 100%;
  background-image: url(${background6});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* min-height: 100vh; */
  padding: 400px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 800px) {
    padding: 200px 0;
  }
`;

export const Section4Wrapper = styled.section`
  width: 100%;
  background-image: url(${background4});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  padding: 200px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 800px) {
    padding: 100px 0;
    min-height: auto;
  }
`;

export const Section5Wrapper = styled.section`
  width: 100%;
  background-image: url(${background});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0;
`;

export const Section1Image1 = styled.img`
  width: 100%;
  margin-bottom: 120px;
`;

export const Section1Image2 = styled.img`
  width: 100%;
`;

export const Section4Title = styled(H1)`
  span {
    background: linear-gradient(
      222.2deg,
      #ffb084 18.48%,
      #f02b00 52.72%,
      #ffffff 88.12%
    );

    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const Section3Boxes = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 16px;

  > {
    :nth-child(1) {
      background: linear-gradient(
        168.94deg,
        rgba(12, 15, 24, 0.7) 9.79%,
        rgba(44, 90, 142, 0.7) 59.98%,
        rgba(43, 211, 249, 0.7) 98.76%
      );
    }

    :nth-child(2) {
      background: linear-gradient(
        168.94deg,
        rgba(12, 15, 24, 0.7) 9.79%,
        rgba(44, 90, 142, 0.7) 59.98%,
        rgba(43, 211, 249, 0.7) 98.76%
      );
    }

    :nth-child(3) {
      background: linear-gradient(
        168.94deg,
        rgba(12, 15, 24, 0.7) 9.79%,
        rgba(44, 90, 142, 0.7) 59.98%,
        rgba(43, 211, 249, 0.7) 98.76%
      );
    }

    :nth-child(4) {
      background: linear-gradient(
        168.94deg,
        rgba(12, 15, 24, 0.7) 9.79%,
        rgba(44, 90, 142, 0.7) 59.98%,
        rgba(43, 211, 249, 0.7) 98.76%
      );
    }
  }

  > div {
    border: 0.5px solid ${({ theme }) => theme.gray}80;
    box-shadow: 0px 4.06px 28.63px 0px rgba(0, 0, 0, 0.25);
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    border-radius: 32px;
    div > * {
      color: ${({ theme }) => theme.white};
    }
  }
  img {
    width: 100%;
  }

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`;

export const Main = styled.div`
  max-width: 1600px;
  padding: 0 100px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 1000px) {
    padding: 0 30px;
  }
`;

export const Section5Text = styled.h2`
  font-weight: 400;
  font-size: 180px;
  letter-spacing: -0.04em;
  line-height: 181.67px;
  text-align: center;
  color: ${({ theme }) => theme.white};
  margin: 0;

  @media (max-width: 1000px) {
    font-size: 80px;
    line-height: 76.7px;
  }
`;

export const GradientText = styled.div`
  > * {
    background: linear-gradient(
      92.75deg,
      #37ffd7 -8.27%,
      #dbfff8 33.04%,
      #dbfff8 66.03%,
      #01f1c1 85.28%,
      #236488 106.41%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const ClockWrapper = styled.div`
  margin-bottom: 100px;
  background: radial-gradient(
    50% 316.65% at 50% 0%,
    rgba(98, 98, 111, 0.34) 0%,
    rgba(42, 42, 52, 0.2754) 63%,
    rgba(14, 14, 18, 0.2414) 100%
  );
  border-radius: 75.15px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  backdrop-filter: blur(11.099713325500488px);
  border: 1.75px solid #909090;
  padding: 30px 130px;

  @media (max-width: 1000px) {
    padding: 12px;
    border-radius: 35px;
  }
`;

export const ClockNumWrapper = styled.div`
  width: 200px;
  background: linear-gradient(
    180deg,
    rgba(25, 106, 105, 0) 0%,
    #1e9562 35%,
    rgba(14, 21, 43, 0) 100%
  );

  h1 {
    font-size: 90px;
    margin: 0;
    font-weight: 400;
    color: ${({ theme }) => theme.white};
  }
  p {
    font-size: 20px;
    margin: 0;
    color: ${({ theme }) => theme.white};
  }

  @media (max-width: 1000px) {
    width: 70px;
    background: none;

    h1 {
      font-size: 50px;
      margin: 0;
      font-weight: 400;
      color: ${({ theme }) => theme.white};
    }
    p {
      font-size: 15px;
      margin: 0;
      color: ${({ theme }) => theme.white};
    }
  }
`;

export const ClockSeparator = styled.div`
  width: 2px;
  height: 75px;
  flex: none;
  background-color: ${({ theme }) => theme.white};
`;

export const SeparatorSmall = styled.div`
  width: 2px;
  height: 45px;
  background-color: ${({ theme }) => theme.white};
  @media (max-width: 1000px) {
    width: 45px;
    height: 2px;
  }
`;

export const PartnersLogoWrapper = styled.div`
  /* padding: 35px 42px; */
  margin: 15px 13px;
  flex: none;
  border-radius: 200px;
  height: 90px;
  width: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.75px solid #909090;
  background: radial-gradient(
    50% 316.65% at 50% 0%,
    rgba(98, 98, 111, 0.34) 0%,
    rgba(42, 42, 52, 0.2754) 63%,
    rgba(14, 14, 18, 0.2414) 100%
  );
  backdrop-filter: blur(5.894180774688721px);

  svg {
    max-width: 160px;
    max-height: 34px;
  }

  @media (max-width: 1000px) {
    height: 70px;
    width: 200px;

    svg {
      width: 120px;
      max-height: 23px;
    }
  }
`;

// export const ContractAddressWrapper = styled(PartnersLogoWrapper)`
//   width: auto;
//   height: auto;
//   padding: 16px 30px;
//   gap: 20px;
//   margin-top: 100px;

//   @media (max-width: 1000px) {
//     width: 100%;
//     margin-top: 70px;

//     border-radius: 44px;
//     flex-direction: column;
//   }
// `;

export const ContractAddressWrapper = styled.div`
  /* padding: 35px 42px; */

  border: 1.75px solid #909090;
  background: radial-gradient(
    50% 316.65% at 50% 0%,
    rgba(98, 98, 111, 0.34) 0%,
    rgba(42, 42, 52, 0.2754) 63%,
    rgba(14, 14, 18, 0.2414) 100%
  );
  backdrop-filter: blur(5.894180774688721px);

  width: auto;
  height: auto;
  padding: 16px 30px;
  gap: 20px;
  margin-top: 100px;
  border-radius: 200px;
  display: flex;
  align-items: center;

  @media (max-width: 1000px) {
    max-width: 100%;
    margin-top: 70px;
    padding: 16px 20px;

    border-radius: 44px;
    flex-direction: column;
  }
`;

// export const DownloadButtonWrapper = styled(PartnersLogoWrapper)`
//   width: auto;
//   height: auto;
//   padding: 20px 30px;
//   gap: 20px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

export const DownloadButtonWrapper = styled.div`
  padding: 20px 30px;
  margin: 15px 13px;
  flex: none;
  border-radius: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border: 1.75px solid #909090;
  background: radial-gradient(
    50% 316.65% at 50% 0%,
    rgba(98, 98, 111, 0.34) 0%,
    rgba(42, 42, 52, 0.2754) 63%,
    rgba(14, 14, 18, 0.2414) 100%
  );
  backdrop-filter: blur(5.894180774688721px);

  @media (max-width: 1000px) {
  }
`;

export const Button = styled.button`
  padding: 20px 30px;
  margin: 15px 13px;
  flex: none;
  border-radius: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border: 1.75px solid #909090;
  background: radial-gradient(
    50% 316.65% at 50% 0%,
    rgba(98, 98, 111, 0.34) 0%,
    rgba(42, 42, 52, 0.2754) 63%,
    rgba(14, 14, 18, 0.2414) 100%
  );
  backdrop-filter: blur(5.894180774688721px);
  cursor: pointer;
  @media (max-width: 1000px) {
  }
`;

export const ChapterWrapper = styled(PartnersLogoWrapper)`
  width: auto;
  height: auto;
  padding: 7px 29px;
  border-radius: 9px;
  margin: 0;
  @media (max-width: 800px) {
    padding: 5px 20px;
  }
`;
