import { copyLink } from 'helpers/helpers';
import Marquee from 'react-fast-marquee';
import {
  CopyIcon,
  Div,
  H1,
  P1,
  P3,
  PartnersLogo1,
  PartnersLogo2,
  PartnersLogo3,
  PartnersLogo4,
  colors,
} from 'styles';
import {
  ContractAddressWrapper,
  GradientText,
  Main,
  PartnersLogoWrapper,
  Section4Wrapper,
  SeparatorSmall,
} from '../Home.styled';

const partners = [
  { Logo: PartnersLogo1 },
  { Logo: PartnersLogo2 },
  { Logo: PartnersLogo3 },
  { Logo: PartnersLogo4 },

  { Logo: PartnersLogo1 },
  { Logo: PartnersLogo2 },
  { Logo: PartnersLogo3 },
  { Logo: PartnersLogo4 },

  { Logo: PartnersLogo1 },
  { Logo: PartnersLogo2 },
  { Logo: PartnersLogo3 },
  { Logo: PartnersLogo4 },
];

export const Section4 = () => {
  return (
    <Section4Wrapper id='section3'>
      <GradientText>
        <H1 mb='30'>Partners</H1>
      </GradientText>
      <Main>
        <Div $flex $justifyCenter $mb='130px' $mbSM='50px'>
          <P1 center color={colors.white} maxWidth='800px'>
            Our esteemed network of partners, each a testament to their trust in
            Akasha's vision for innovation and success in DeFi.
          </P1>
        </Div>
      </Main>

      <Marquee loop={0} speed={40}>
        {partners?.map(({ Logo }, i) => {
          return (
            <PartnersLogoWrapper key={i}>
              <Logo />
            </PartnersLogoWrapper>
          );
        })}
      </Marquee>

      <Marquee loop={0} speed={40} direction='right'>
        {partners?.map(({ Logo }, i) => {
          return (
            <PartnersLogoWrapper key={i}>
              <Logo />
            </PartnersLogoWrapper>
          );
        })}
      </Marquee>

      <ContractAddressWrapper>
        <GradientText>
          <P1>Contract Address</P1>
        </GradientText>
        <SeparatorSmall />
        <P3 color={colors.white}>0x54b659832f59c24ceC0E4A2Cd193377F1BCEfc3c</P3>
        <Div
          $pointer
          onClick={() =>
            copyLink(
              '0x54b659832f59c24ceC0E4A2Cd193377F1BCEfc3c',
              'Contract Address has been copied'
            )
          }
        >
          <CopyIcon />
        </Div>
      </ContractAddressWrapper>
    </Section4Wrapper>
  );
};
