import { motion } from 'framer-motion';
import styled from 'styled-components';
import { colors } from './GlobalStyles';

export interface DivStyled {
  $desktop?: boolean;
  $mobile?: boolean;
  $box?: boolean;
  $m?: string;
  $mt?: string;
  $mb?: string;
  $ml?: string;
  $mr?: string;
  $mSM?: string;
  $mtSM?: string;
  $mbSM?: string;
  $mlSM?: string;
  $mrSM?: string;
  $width?: string;
  $height?: string;
  $maxWidth?: string;
  $minWidth?: string;
  $widthMD?: string;
  $alignEnd?: boolean;
  $alignCenter?: boolean;
  $relative?: boolean;
  $pointer?: boolean;
  $gap?: string;
  $between?: boolean;
  $center?: boolean;
  $flexNone?: boolean;
  $flex?: boolean;
  $justifyEnd?: boolean;
  $column?: boolean;
  $wrap?: boolean;
  $textAlignLeft?: boolean;
  $textAlignRight?: boolean;
  $textAlignCenter?: boolean;
  $justifyCenter?: boolean;
  $p?: string;
  $pSM?: string;
  $pMD?: string;
  $minWidthSM?: string;
  $heightSM?: string;
  $widthSM?: string;
  $maxWidthSM?: string;
  $reverseSM?: boolean;
  $columnSM?: boolean;
  $rowSM?: boolean;
  $gapSM?: string;
  $centerSM?: boolean;
  $flexSM?: boolean;
  $gapMD?: string;
  $borderRadius?: string;
  $background?: any;
  $backgroundOverlay?: any;
  viewport?: any;
  variants?: any;
  $hoverZoom?: boolean;
  $borderColor?: string;
}

export const Div = styled(motion.div)<DivStyled>`
  ${({ $box, theme }) =>
    $box &&
    `
  background: radial-gradient(158.48% 294.91% at 50% 320.96%, rgba(0, 204, 240, 0.98) 0%, rgba(0, 208, 240, 0) 100%),
linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));


    border: 1px solid ${theme.gray}4c;
    box-shadow: 0px 70.82px 130.86px 0px rgba(0, 0, 0, 0.1);
    padding: 24px;
    border-radius: 24px;
    
    
  `}

  ${({ $borderColor }) => $borderColor && `border-color: ${$borderColor}`};

  margin: ${(props) => props.$m};
  margin-top: ${(props) => props.$mt};
  margin-bottom: ${(props) => props.$mb};
  margin-left: ${(props) => props.$ml};
  margin-right: ${(props) => props.$mr};
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};

  ${({ $maxWidth }) => $maxWidth && `max-width: ${$maxWidth}`};
  ${({ $minWidth }) => $minWidth && `min-width: ${$minWidth}`};
  ${({ $borderRadius }) => $borderRadius && `border-radius: ${$borderRadius}`};
  ${({ $background }) =>
    $background &&
    `
    
    background: url(${$background});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    // background-attachment: fixed;
  
    `};

  ${({ $backgroundOverlay, $background }) =>
    $backgroundOverlay &&
    `
    
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${$background});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    // background-attachment: fixed;
    `};

  ${({ $hoverZoom }) =>
    $hoverZoom &&
    `
      :hover {
        background-size: 130% !important;
        transform: scale(1.2);
      }
    `};

  padding: ${(props) => props.$p};

  ${({ $flex }) => $flex && 'display: flex'};
  ${({ $flexNone }) => $flexNone && 'flex: none'};
  ${({ $center }) => $center && 'align-items: center; justify-content:center;'};
  ${({ $between }) => $between && 'justify-content: space-between;'};
  ${({ $gap, $flex }) => $flex && $gap && `gap:${$gap}`};
  ${({ $pointer }) => $pointer && 'cursor: pointer'};
  ${({ $relative }) => $relative && 'position: relative'};
  ${({ $alignCenter }) => $alignCenter && 'align-items: center'};
  ${({ $alignEnd }) => $alignEnd && 'align-items: flex-end'};
  ${({ $justifyEnd }) => $justifyEnd && 'justify-content: flex-end'};
  ${({ $justifyCenter }) => $justifyCenter && 'justify-content: center'};
  ${({ $textAlignCenter }) => $textAlignCenter && 'text-align: center'};
  ${({ $textAlignRight }) => $textAlignRight && 'text-align: right'};
  ${({ $textAlignLeft }) => $textAlignLeft && 'text-align: left'};

  ${({ $column }) => $column && 'flex-direction: column'};
  ${({ $wrap }) => $wrap && 'flex-wrap: wrap'};

  @media (max-width: 1450px) {
    width: ${(props) => props.$widthMD};
    padding: ${(props) => props.$pMD};
    ${({ $gapMD }) => $gapMD && `gap: ${$gapMD}`};
  }

  @media (max-width: 1000px) and (orientation: landscape) {
    height: auto;
    padding: ${(props) => props.$pSM};
    margin: ${(props) => props.$mSM};
    margin-top: ${(props) => props.$mtSM};
    margin-bottom: ${(props) => props.$mbSM};
    margin-left: ${(props) => props.$mlSM};
    margin-right: ${(props) => props.$mrSM};
    width: ${(props) => props.$widthSM};
    ${({ $heightSM }) => $heightSM && `height: ${$heightSM}`};
    max-width: ${(props) => props.$maxWidthSM};
    min-width: ${(props) => props.$minWidthSM};

    ${({ $flexSM }) => $flexSM && 'display: flex'};
    ${({ $centerSM }) =>
      $centerSM && 'align-items: center; justify-content:center;'};
    ${({ $gapSM }) => $gapSM && `gap: ${$gapSM}`};
    ${({ $columnSM }) => $columnSM && 'flex-direction: column'};
    ${({ $reverseSM }) => $reverseSM && 'flex-direction: column-reverse'};
    ${({ $rowSM }) => $rowSM && 'flex-direction: row'};
  }

  @media (max-width: 800px) {
    height: auto;
    padding: ${(props) => props.$pSM};
    margin: ${(props) => props.$mSM};
    margin-top: ${(props) => props.$mtSM}px;
    margin-bottom: ${(props) => props.$mbSM}px;
    margin-left: ${(props) => props.$mlSM}px;
    margin-right: ${(props) => props.$mrSM}px;
    width: ${(props) => props.$widthSM};
    min-width: ${(props) => props.$minWidthSM};
    ${({ $heightSM }) => $heightSM && `height: ${$heightSM}`};
    max-width: ${(props) => props.$maxWidthSM};
    ${({ $flexSM }) => $flexSM && 'display: flex'};
    ${({ $centerSM }) =>
      $centerSM && 'align-items: center; justify-content:center;'};
    ${({ $gapSM }) => $gapSM && `gap: ${$gapSM}`};
    ${({ $columnSM }) => $columnSM && 'flex-direction: column'};
    ${({ $reverseSM }) => $reverseSM && 'flex-direction: column-reverse'};
    ${({ $rowSM }) => $rowSM && 'flex-direction: row'};
  }

  ${({ $desktop }) =>
    $desktop &&
    `
      @media (max-width: 800px) {
      display: none;
    }
  
    `};

  ${({ $mobile }) =>
    $mobile &&
    `
      @media (min-width: 800px) {
      display: none;
    }

    `};
`;

export const Separator = styled.div<any>`
  width: 100%;
  border-top: 1px solid ${({ $color, theme }) => ($color ? $color : theme.gray)};

  ${({ $m }) => $m && `margin: ${$m};`}

  @media (max-width: 800px) {
    ${({ $mSM }) => $mSM && `margin: ${$mSM};`}
  }
`;

export const DisableSelection = ` 
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important; 
  -moz-user-select: none !important;
  -ms-user-select: none !important; 
  user-select: none !important; 
`;

export const Table = styled.table`
  /* background: ${(props) => props.theme.white}; */
  width: 100%;
  margin-bottom: 24px;
  border-radius: 12px;
  border-collapse: collapse;
  border-style: hidden;
  overflow: hidden;
  border-collapse: separate;

  thead {
    /* background-color: ${({ theme }) => theme.black}cc; */
    color: ${({ theme }) => theme.white};
    div {
      color: ${({ theme }) => theme.white};
    }
  }

  thead tr th {
    text-transform: uppercase;
  }

  tbody tr td {
    color: ${({ theme }) => theme.white};
    border-collapse: collapse;
  }

  tbody {
    tr {
      background: ${(props) => props.theme.white}26;
      margin-bottom: 10px;
    }
  }

  tbody tr:last-child td {
    box-shadow: none;
  }

  > * {
    border-collapse: collapse;
  }

  th {
    padding: 0;
    height: 60px;
  }

  td {
    height: 55px;
    padding: 0;
    text-align: center;
  }

  @media (max-width: 800px) {
    tbody tr td > div {
      font-size: 13px;
    }

    thead tr th > div {
      font-size: 14px;
    }
  }

  @media (max-width: 380px) {
    tbody tr td > div {
      font-size: 10px;
    }

    thead tr th > div {
      font-size: 12px;
    }
  }
`;

export const ProfilePicture = styled.div<any>`
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.size ? props.size : '102px')};
  width: ${(props) => (props.size ? props.size : '102px')};
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;

  ${DisableSelection}
`;

export const ProfileImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  ${DisableSelection};
`;

export const ProfileImagePlaceholder = styled.div<any>`
  font-size: ${(props) => (props.font ? props.font : '102px')};
  color: #ffffff;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.black}4c;
  height: ${(props) => (props.size ? props.size : '102px')};
  width: ${(props) => (props.size ? props.size : '102px')};
  border-radius: 50%;

  @media (max-width: 800px) {
    font-weight: 600;
  }
`;

export const UserImageCircle = ({
  image,
  size,
  margin,
  fontSize,
  avatarSize,
  disabled,
}: any) => {
  return (
    <ProfilePicture
      size={size ? size : '56px'}
      style={{
        margin: margin,
      }}
    >
      {image ? (
        <ProfileImage src={image} alt='Upload' />
      ) : (
        <ProfileImagePlaceholder
          disabled={disabled}
          size={size ? size : '56px'}
          font={fontSize}
        >
          <svg
            width={avatarSize ?? '24px'}
            height={avatarSize ?? '25px'}
            viewBox='0 0 24 25'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M11.9999 12.0631C15.3138 12.0631 18.0002 9.3767 18.0002 6.06286C18.0002 2.74902 15.3138 0.0626221 11.9999 0.0626221C8.68608 0.0626221 5.99968 2.74902 5.99968 6.06286C5.99968 9.3767 8.68608 12.0631 11.9999 12.0631Z'
              fill={colors.white}
            />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M12.0005 13.5626C7.99532 13.5626 0 15.5727 0 19.5628V22.5629C0 23.388 0.675027 24.063 1.50006 24.063H22.5009C23.3259 24.063 24.001 23.388 24.001 22.5629V19.5628C24.001 15.5727 16.0056 13.5626 12.0005 13.5626Z'
              fill={colors.white}
            />
          </svg>
        </ProfileImagePlaceholder>
      )}
    </ProfilePicture>
  );
};

export const Img = styled.img<{
  $m?: string;
  $mt?: string;
  $mb?: string;
  $ml?: string;
  $mr?: string;
  $mSM?: string;
  $mtSM?: string;
  $mbSM?: string;
  $mlSM?: string;
  $mrSM?: string;
  $widthSM?: string;
  $minWidth?: string;
  $heightSM?: string;
  $maxWidthSM?: string;
  $maxHeightSM?: string;
  $height?: string;
  $width?: string;
  $maxWidth?: string;
  $maxHeight?: string;
}>`
  margin: ${(props) => props.$m};
  margin-top: ${(props) => props.$mt}px;
  margin-bottom: ${(props) => props.$mb}px;
  margin-left: ${(props) => props.$ml}px;
  margin-right: ${(props) => props.$mr}px;
  height: ${(props) => props.$height};
  width: ${(props) => props.$width};
  max-width: ${(props) => props.$maxWidth};
  max-height: ${(props) => props.$maxHeight};
  min-width: ${(props) => props.$minWidth};

  @media (max-width: 1000px) and (orientation: landscape) {
    margin: ${(props) => props.$mSM};
    margin-top: ${(props) => props.$mtSM}px;
    margin-bottom: ${(props) => props.$mbSM}px;
    margin-left: ${(props) => props.$mlSM}px;
    margin-right: ${(props) => props.$mrSM}px;
    width: ${(props) => props.$widthSM};
    height: ${(props) => props.$heightSM};
    max-width: ${(props) => props.$maxWidthSM};
  }

  @media (max-width: 800px) {
    margin: ${(props) => props.$mSM};
    margin-top: ${(props) => props.$mtSM}px;
    margin-bottom: ${(props) => props.$mbSM}px;
    margin-left: ${(props) => props.$mlSM}px;
    margin-right: ${(props) => props.$mrSM}px;
    width: ${(props) => props.$widthSM};
    max-width: ${(props) => props.$maxWidthSM};
    height: ${(props) => props.$heightSM};
    max-height: ${(props) => props.$maxHeightSM};
  }
`;
