import { Link } from 'react-router';
import styled from 'styled-components';

export const FooterStyled = styled.footer`
  padding: 50px 100px 24px 100px;
  background: #040707;

  img {
    width: 220px;
  }

  @media (max-width: 1000px) {
    padding: 50px 30px 24px 30px;

    img {
      width: 150px;
    }
  }
`;

export const FooterText = styled.p`
  font-size: 18px;
  font-weight: 400;
  color: ${({ theme }) => theme.white};
`;

export const FooterLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.white};
`;

export const SubButtonStyled = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`;
