import { useState } from 'react';
import styled from 'styled-components';
import closed from '../../assets/icons/closedEye.svg';
import opened from '../../assets/icons/openedEye.svg';
import { Img } from 'styles';

const Input = styled.input<{ max: string | number }>`
  font-family: 'Montserrat' !important;

  width: 100%;
  height: 50px;
  gap: 0px;
  border-radius: 16px;
  outline: none;

  color: ${({ theme }) => theme.white};
  background: none;
  &::placeholder {
    color: ${({ theme }) => theme.white};
  }
  font-size: 16px;
  border: none;

  /* Disable autofill background */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: ${({ theme }) => theme.white};
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px rgba(35, 35, 35, 0);
  }

  ::-ms-reveal,
  ::-ms-clear {
    display: none !important;
  }
`;

const InputContainer = styled.div<{ padding: string }>`
  width: 100%;
  height: 50px;
  gap: 0px;
  border-radius: 16px;
  outline: none;
  margin: 10px 0 15px 0;
  padding: 0 20px;
  color: ${({ theme }) => theme.white};
  display: flex;
  &::placeholder {
    color: ${({ theme }) => theme.white};
  }
  display: flex;
  align-items: center;
  font-size: 16px;
  border: 1.75px solid #909090;
  background: radial-gradient(
    50% 316.65% at 50% 0%,
    rgba(98, 98, 111, 0.34) 0%,
    rgba(42, 42, 52, 0.2754) 63%,
    rgba(14, 14, 18, 0.2414) 100%
  );
  backdrop-filter: blur(5.894180774688721px);
`;

const InpCont = styled.div<{ val: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  :focus-within {
    .lab {
      height: 12px;
      opacity: 0.7;
    }
  }
  ${({ val }) =>
    val
      ? ` .lab {
    height: 12px;
    opacity: 0.7;
  }`
      : ``};
`;

export const Label = styled.label`
  font-size: 14px;
  font-weight: 400;
  line-height: 17.61px;
  color: #fff;
`;

export const InputComponent = ({ Icon, ...props }: any) => {
  const [viewPass, setViewPass] = useState(false);

  return (
    <>
      {props.label && <Label>{props.label}</Label>}
      <InputContainer style={props.style} padding={props.padding}>
        <InpCont val={props.value}>
          <Input
            {...props}
            type={
              props.type !== 'password'
                ? props.type
                : viewPass
                ? 'text'
                : 'password'
            }
          />
        </InpCont>

        {props.type === 'password' && (
          <Img
            width={25}
            src={viewPass ? opened : closed}
            alt='view password'
            onClick={() => setViewPass(!viewPass)}
          />
        )}
        {Icon && <Icon />}
      </InputContainer>
    </>
  );
};
